/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, MouseEvent, KeyboardEvent } from 'react';
import './ThemeMarker.scss';
import { Dot } from './Dot';
import { Pin } from './Pin';
import { IMarker, IMarkerShape, IPolyline, IVertices } from '../../../store/Markers/Marker';
import { InfoBox } from './InfoBox';
import { useDispatch, useSelector } from 'react-redux';
import { MarkerDialogOpen, MarkerIndexDialogOpen, Polylines } from '../../../store/Markers/Selector';
import { setMarkerDialogAsOpen, setMarkerIndexDialogAsOpen, updatePolylines } from '../../../store/Markers/Actions';
import ReactGA from 'react-ga4';
import { Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Polyline } from './Polyline';
import { isNullEmptyOrUndefined } from '../../../utils/helpers/ObjectHelper';
import { Vehicle } from './Vehicle/Vehicle';
import { SelectedFilters } from '../../../store/Filters/Selector';
import { vehicles } from '../../../constants/VehicleConstants';

export interface IMarkerProps {
  getLangString: (value: string) => string;
  marker: IMarker;
  isVehicle: boolean;
  zoomLevel: number;
  lat?: number;
  lng?: number;
  map: any;
  maps: any;
}

export const Marker = (props: IMarkerProps): JSX.Element => {
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
  const [polylinePath, setPolylinePath] = useState<IVertices[][]>([]);
  const [polylineId, setPolylineId] = useState<string>('');

  const dispatch = useDispatch();
  const markerDialogOpen = useSelector(MarkerDialogOpen);
  const markerIndexOpen = useSelector(MarkerIndexDialogOpen);
  const selectedFilters = useSelector(SelectedFilters);
  const polylines = useSelector(Polylines);
  const history = useHistory();

  useEffect(() => {
    if (markerDialogOpen !== props.marker.id) {
      setShowInfoBox(false);
    } else {
      setShowInfoBox(true);
      handleSiblings(markerIndexOpen ?? 0);
    }
  }, [markerDialogOpen, props.marker.id, markerIndexOpen]);


  const handlePolylines = (removeVehicles: boolean) => {
    if (!isNullEmptyOrUndefined(polylines) && polylines.length > 0) {
      const polylinesToClean = removeVehicles ? polylines.filter(poly => vehicles.some(v => poly.id.includes(v.name))) : polylines.filter(poly => !vehicles.some(v => poly.id.includes(v.name)));
      if (!isNullEmptyOrUndefined(polylinesToClean) && polylinesToClean.length > 0) {
        polylinesToClean.forEach((polyline: IPolyline) => {
          polyline.polylines.forEach((poly: any) => {
            if (!isNullEmptyOrUndefined(poly)) {
              poly.setMap(null);
            }
          })
        })
        const newPolylines = polylines.filter(polyline => !polylinesToClean.some(poly => poly.id === polyline.id));
        dispatch(updatePolylines(newPolylines));
      }
    }
  };

  useEffect(() => {
    if (selectedFilters.some(filter => filter.title === 'Turer')) {
      if (props.marker.icon !== null) {
        handlePath(props.marker.markerShapes, 0);
      }
    } else {
      handlePolylines(true);
    }
  }, [selectedFilters]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const pinId = new URLSearchParams(location.search).get('pin');
      if (pinId) {
        dispatch(setMarkerDialogAsOpen(pinId));
      } else {
        dispatch(setMarkerDialogAsOpen('close'));
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const handleClickOnMarker = (event: MouseEvent | KeyboardEvent | undefined) => {
    const params = new URLSearchParams();
    if (showInfoBox) {
      handlePolylines(false);
      dispatch(setMarkerDialogAsOpen('close'));
      dispatch(setMarkerIndexDialogAsOpen(0));
    } else {
      handlePolylines(false);
      dispatch(setMarkerDialogAsOpen(props.marker.id));
      dispatch(setMarkerIndexDialogAsOpen(0));
      params.append('pin', props.marker.id);
    }
    history.push({ search: params.toString() });
    event?.stopPropagation();
    event?.preventDefault();
    ReactGA.event({ category: 'Marker', action: `Clicked on marker: ${props.marker.title}` });
  };

  const handlePath = (markerShapes: IMarkerShape[], siblingIndex: number) => {
    setPolylinePath([]);
    const newPolylinePath: IVertices[][] = [];
    if (!isNullEmptyOrUndefined(markerShapes)) {
      markerShapes.forEach((markerShape: IMarkerShape) => {
        if (!isNullEmptyOrUndefined(markerShape.vertices) && markerShape.vertices.length > 0) {
          newPolylinePath.push(markerShape.vertices);
        }
      });
      setPolylinePath(newPolylinePath);
      setPolylineId(
        `${props.marker.types.some(t => t.title === 'Turer') ? props.marker.icon + '-' : ''}${
          props.marker.id
        }-${siblingIndex}`
      );
    } else {
      setPolylinePath([]);
    }
  };

  const handleSiblings = (markerIndex: number) => {
    const siblingIndex = markerIndex - 1;
    handlePolylines(false);
    if (siblingIndex === -1) {
      handlePath(props.marker.markerShapes, 0);
    } else if (
      props.marker.siblings &&
      props.marker.siblings?.length > 0 &&
      props.marker.siblings[siblingIndex].markerShapes &&
      props.marker.siblings[siblingIndex].markerShapes.length > 0
    ) {
      handlePath(props.marker.siblings[siblingIndex].markerShapes, siblingIndex);
    } else {
      handlePath([], 0);
    }
  };

  const getColorForMarkerShape = () => {
    const type =
      selectedFilters && props.marker && props.marker.types.length > 0
        ? props.marker.types.find(t => selectedFilters.find(f => t.title === f.title))
        : !selectedFilters && props.marker && props.marker.types.length > 0
        ? props.marker.types[0]
        : undefined;

        const vehicle = vehicles.find(v => v.type === props.marker.icon);
        const color = type?.title !== "Turer" ? type?.class : vehicle ? vehicle.color : undefined;

    switch (color) {
      case 'dark-blue':
        return '#035e8e';
      case 'light-blue':
        return '#037dba';
      case 'light-blue-2':
        return '#00AAFF';
      case 'dark-green-2':
        return '#017033';
      case 'grey':
        return '#808080';
      case 'brown':
        return '#B59243';
      case 'dark-brown':
        return '#6d5827';
      case 'red':
        return '#D3454E';  
      default:
        return '#035e8e';
    }
  };

  const getColorForTourMarker = () => {
      const type = selectedFilters && props.marker && props.marker.types.length > 0 ?
          props.marker.types.find(t => selectedFilters.find(f => t.title === f.title)) :
          !selectedFilters && props.marker && props.marker.types.length > 0 ?
              props.marker.types[0] :
              undefined;
              
      const vehicle = vehicles.find(v => v.type === props.marker.icon);
      const color = type?.title !== "Turer" ? type?.class : vehicle ? vehicle.color : undefined;
  
      return color;
  };

  const markerType = props.zoomLevel >= 7 && props.zoomLevel < 12 ? 'dot-type' : 
    props.zoomLevel >= 12 && props.isVehicle ? 'vehicle-type' : 
    props.zoomLevel >= 12 ? 'pin-type' : '';

  return (
    <React.Fragment>
      <Hidden smDown>
        {showInfoBox && (
          <React.Fragment>
            <InfoBox
              getLangString={props.getLangString}
              marker={props.marker}
              markerType={markerType}
              setShowInfoBox={setShowInfoBox}
            />
            {polylinePath && polylinePath.length > 0 && !props.isVehicle ? (
              <Polyline
                id={polylineId}
                color={getColorForMarkerShape()}
                listOfVertices={polylinePath}
                map={props.map}
                maps={props.maps}
              />
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )}
      </Hidden>
      {props.zoomLevel >= 12 &&
        <React.Fragment>
          {props.isVehicle ?
            <React.Fragment>
              <Vehicle
                active={showInfoBox}
                getLangString={props.getLangString}
                marker={props.marker}
                onClick={(event) => { handleClickOnMarker(event); return false; }}
                typeOfVehicle={props.marker.icon ? props.marker.icon : ''}
                color={getColorForTourMarker() ?? ''}
              />
              {polylinePath && polylinePath.length > 0 &&
                <Polyline
                  id={polylineId}
                  color={getColorForMarkerShape()}
                  listOfVertices={polylinePath}
                  map={props.map}
                  maps={props.maps}
                />
              }
            </React.Fragment>
            :
            <Pin getLangString={props.getLangString} marker={props.marker} active={showInfoBox} onClick={(event) => { handleClickOnMarker(event); return false; }} />
          }
        </React.Fragment>
      }
      {(props.zoomLevel >= 7 && props.zoomLevel < 12) &&
          <Dot getLangString={props.getLangString} 
          marker={props.marker} 
          active={showInfoBox} 
          onClick={(event) => handleClickOnMarker(event)} 
          color={getColorForTourMarker()} />
      }
    </React.Fragment>
  );
};
