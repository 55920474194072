/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    AccountInfo,
    SilentRequest,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../auth-config";
import { createContext, useContext, useState } from "react";
import React from "react";
import { ADMIN_GROUP_ID } from "../Constants";
export interface Account {
    name: string | undefined;
    login: string | undefined;
    aadAccess?: boolean;
    fromValidTenant: boolean;
}

const AuthContext = createContext<{
    user: Account | null | undefined;
    logOut(): void;
    getApiToken(): Promise<string>;
    refreshTokensAndUserInfo(): Promise<void>;
}>({} as any);

const msalInstance = new PublicClientApplication(msalConfig);

let accessToken: string;

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const useAuth = (): any => {
    return useContext(AuthContext);
}

export interface IAuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = (props: IAuthProviderProps): JSX.Element => {
    // handle auth redired/do all initial setup for msal
    const [token, setToken] = useState<string>(accessToken ?? "");
    const [user, setUser] = useState<Account | null>();
    const [account, setAccount] = useState<AccountInfo | null>();

    const refreshTokensAndUserInfo = async () => {
        await msalInstance.initialize();
        const redirectResponse = await msalInstance.handleRedirectPromise();
        let accountInfo: AccountInfo | null = null;
        try {
            if (redirectResponse !== null) {

                // Check if the redirectResponse have expired
                if (redirectResponse.expiresOn && redirectResponse.expiresOn < new Date()) {
                    // console.log('redirect response was expired');
                    // The token is expired, let's try to get a new one
                    const accessTokenRequest: SilentRequest = {
                        ...loginRequest,
                        account: redirectResponse.account,
                    };

                    // console.log('Aquire accesstoken silently.. ');
                    const accessTokenResponse = await msalInstance.acquireTokenSilent(
                        accessTokenRequest
                    );

                    // console.log('Got the access token response from the slient request, when the redirect isnt null...', accessTokenResponse);
                    accessToken = accessTokenResponse.accessToken;
                } else {
                    // console.log('redirect response was not expired');
                    accessToken = redirectResponse.accessToken;
                    // console.log('Got the access token from redirect response', redirectResponse);
                }

                accountInfo = msalInstance.getActiveAccount();

                // console.log('Account information', accountInfo);

            } else {
                // console.log('redirect response was null');
                // Check if user signed in
                accountInfo = msalInstance.getActiveAccount();

                if (accountInfo === null) {
                    // console.log('Account info was null .. so starting redirect!');
                    msalInstance.loginRedirect(loginRequest);
                    return;
                }

                const accessTokenRequest: SilentRequest = {
                    ...loginRequest,
                    account: accountInfo,
                };

                // console.log('Aquire accesstoken silently.. ');
                const accessTokenResponse = await msalInstance.acquireTokenSilent(
                    accessTokenRequest
                );

                // console.log('Got the access token response from the slient request', accessTokenResponse);
                accessToken = accessTokenResponse.accessToken;
            }
        } catch (error) {
            console.error('Error when trying to get the access token', error);

            // Reload the browser to force a new login, we have seen some 
            // strange errors when the user is logged in for a long time and the
            // refresh fails.
            window.location.reload();
        }

        // Split the home account id to get the id for the tenant for the user. That is the secound 
        // part of the home account id. Check that against the tenant id, if they are the same, the user 
        // is from the current tenant.
        const homeAccountIdParts = accountInfo?.homeAccountId?.split('.');
        let userTenantId = "";
        let userFromCurrentTenant = false;
        if (homeAccountIdParts?.length !== 2) {
            console.error('The home account id was not in the expected format, letting all users in. The home account id was:', accountInfo?.homeAccountId);
            userFromCurrentTenant = true;
        } else {
            userTenantId = homeAccountIdParts[1];
            userFromCurrentTenant = userTenantId === accountInfo?.tenantId;
        }
        setAccount(accountInfo);
        const user: Account = {
            name: accountInfo?.name,
            login: accountInfo?.username,
            fromValidTenant: userFromCurrentTenant
        };
        
        if (
            accountInfo != null &&
            accountInfo.idTokenClaims != null &&
            accountInfo.idTokenClaims.groups != null &&
            accountInfo.idTokenClaims["groups"] != null
        ) {

            // OK, so the user are memeber of a group that are assigned to the APP in AAD
            // let's check if the user is a member of the beta group
            const groups = accountInfo.idTokenClaims["groups"] as string[];

            if (groups.includes(ADMIN_GROUP_ID)) {
                user.aadAccess = true;
            }
        }

        setUser(user);

        // Only set the access token if the user is from the valid tenant, this 
        // is to prevent unnecessary calls to the API.
        if (user?.aadAccess) {
            setToken(accessToken);
        }
    };

    // anropa denna metoden - 
    const getApiToken = async (): Promise<string> => {
        await refreshTokensAndUserInfo();
        return token;
    };

    const logOut = async () => {
        msalInstance.logoutRedirect({ account: account });
    };

    return (
        <AuthContext.Provider value={{ user, logOut, getApiToken, refreshTokensAndUserInfo }}>
            {props.children}
        </AuthContext.Provider>
    );
}