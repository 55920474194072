export interface ISettings {
    defaultFilters: string[];
    defaultEventCategories: string[];
    markerIsLiveHours: number;
}

export enum AdminTools {
    Admin = 1,
    Filter = 2
}
