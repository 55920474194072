import React, { useEffect, useState } from 'react'
import './ThemeFilterTool.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Settings, SettingsErrors, SettingsLoading } from '../../../../store/Settings/Selector';
import { IFilter } from '../../../../store/Filters/Filter';
import { Filters, FiltersLoading } from '../../../../store/Filters/Selector';
import { getFilters } from '../../../../store/Filters/Actions';
import { Button } from '@material-ui/core';
import { Loader } from '../../../common/loader/Loader';
import { updateSettings } from '../../../../store/Settings/Actions';
import { ISettings } from '../../../../store/Settings/Settings';
import { useAuth } from '../../../../services/auth-context';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IFilterToolProps {
    getLangString: (value: string) => string;
}

export const FilterTool = (props: IFilterToolProps): JSX.Element => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [settings, setSettings] = useState<ISettings>({} as ISettings);
    const [filters, setFilters] = useState<IFilter[]>([]);
    const [error, setError] = useState<string>("");
    const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { getApiToken } = useAuth();

    const storeSettings = useSelector(Settings);
    const storeLoadingSettings = useSelector(SettingsLoading);
    const storeSettingErrors = useSelector(SettingsErrors);
    const storeFilters = useSelector(Filters);
    const storeLoadingFilters = useSelector(FiltersLoading);

    useEffect(() => {
        dispatch(getFilters());
    }, []);

    useEffect(() => {
        setSettings(storeSettings);
    }, [storeSettings]);

    useEffect(() => {
        setFilters(storeFilters);
    }, [storeFilters]);

    useEffect(() => {
        setSelectedFilters(settings.defaultFilters);
    }, [settings])

    useEffect(() => {
        if (storeLoadingFilters || storeLoadingSettings) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [storeLoadingFilters, storeLoadingSettings]);

    useEffect(() => {
        if (storeSettingErrors) {
            setError(storeSettingErrors);
        }
    }, [storeSettingErrors]);

    useEffect(() => {
        if (selectedFilters?.every(selectedFilter => settings.defaultFilters?.includes(selectedFilter)) &&
            settings.defaultFilters?.every(defaultFilter => selectedFilters?.includes(defaultFilter))) {
            setDisableSaveBtn(true);
        } else {
            setDisableSaveBtn(false);
        }
    }, [selectedFilters]);

    const handleSelecteFilter = (filter: IFilter) => {
        if (selectedFilters.find(selectedFilter => selectedFilter === filter.title)) {
            setSelectedFilters(selectedFilters.filter(selectedFilter => selectedFilter !== filter.title));
        } else {
            setSelectedFilters([...selectedFilters, filter.title]);
        }
    };

    const handleSaveDefaultFilters = async () => {
        const token = await getApiToken();
        const updatedSettings = { ...settings, defaultFilters: selectedFilters };
        dispatch(updateSettings(updatedSettings, token));
    };

    return (
        <div className="filter-tool-container">
            <span className="filter-tool-title">{props.getLangString('admin.filterTitle')}</span>
            <span className="filter-tool-text">{props.getLangString('admin.filterSubTitle')}</span>
            {loading ?
                <div className="loading-filter-container">
                    <Loader getLangString={props.getLangString} />
                </div>
                :
                error ?
                    <div className="error-tool-container">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="error-tool-icon" />
                        <span className="error-tools-title">{error}</span>
                    </div>
                    :
                    <>
                        <div className="filter-tool-list">
                            {filters.map((filter: IFilter, index: number) =>
                                <div className="filter-tool-list-container" key={index}>
                                    <div className="filter-tool-list-item" onClick={() => handleSelecteFilter(filter)}>
                                        <div className={`filter-tool-list-icon ${selectedFilters?.find(selectedFilter => selectedFilter === filter.title) && "selected"}`}></div>
                                        <span className="filter-tool-list-text">{filter.title}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="filter-tool-button-container">
                            <Button
                                className={`filter-tool-button ${disableSaveBtn && "disabled"}`}
                                onClick={handleSaveDefaultFilters}
                                disabled={disableSaveBtn}
                            >
                                {props.getLangString('admin.save')}
                            </Button>
                        </div>
                    </>
            }
        </div>
    );
}