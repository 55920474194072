import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faLink, faTimes } from '@fortawesome/free-solid-svg-icons';

import './ThemeTopNavigation.scss';
import { Hidden, Drawer, List, ListItem, Button } from '@material-ui/core';
import { clearEventsPageFilters, setEventsPageScrollPosition } from '../../store/Events/Actions';
import { useDispatch } from 'react-redux';
import { menuMapItems, urlArray } from './VisitUrls';
import { GetImageLogo } from '../../utils/helpers/ImageHelper';
import { useAuth } from '../../services/auth-context';

export interface ITopNavigationProps {
  getLangString: (value: string) => string;
}

export const TopNavigation = (props: ITopNavigationProps): JSX.Element => {
  const [showDrawer, setShowDrawer] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const { user, logOut } = useAuth();

  const toggleDrawer = (open: boolean) => {
    setShowDrawer(open);
  };

  const onEventsPageClick = () => {
    if (location.pathname !== '/events') {
      dispatch(setEventsPageScrollPosition(0));
      dispatch(clearEventsPageFilters());
    }
  };

  const isHomePage = location.pathname === '/';

  const mainListItems = () => {
    return (
      <List className="drawer-list">
        <ListItem className="drawer-list-item " onClick={() => toggleDrawer(false)}>
          <NavLink
            to="/"
            exact
            className="drawer-link dark livemap"
            activeClassName="active"
            onClick={() => onEventsPageClick()}
          >
            <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />

            {props.getLangString('topNavigation.liveMap')}
          </NavLink>
        </ListItem>
        <ListItem className="drawer-list-item" onClick={() => toggleDrawer(false)}>
          <NavLink
            to="/events"
            className="drawer-link dark"
            activeClassName="active"
            onClick={() => onEventsPageClick()}
          >
            <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />

            {props.getLangString('topNavigation.whatHappensInRonneby')}
          </NavLink>
        </ListItem>

        <ListItem className="drawer-list-item " onClick={() => toggleDrawer(false)}>
          <NavLink to="/about" className="drawer-link dark" activeClassName="active">
            <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />

            {props.getLangString('topNavigation.aboutRonneby')}
          </NavLink>
        </ListItem>

        {urlArray.map(({ url, name }, id) => (
          <ListItem key={id} className="drawer-list-item " onClick={() => toggleDrawer(false)}>
            <a href={url} className="drawer-link dark" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faLink} className="drawer-item-icon" />
              {name}
            </a>
          </ListItem>
        ))}
      </List>
    );
  };

  const subMainListItems = () => {
    return (
      <List className="drawer-list">
        {menuMapItems.map(({ url, title, active }, id) => (
          <ListItem key={id} className="drawer-list-item has-border" onClick={() => toggleDrawer(false)}>
            {active && (
              <a href={url} className={`drawer-link light`} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />
                {title}
              </a>
            )}
            {!active && (
              <a className={`drawer-link-disable`} aria-disabled="true">
                <FontAwesomeIcon icon={faAngleRight} className="drawer-item-icon" />
                {title}
              </a>
            )}
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <React.Fragment>
      <Hidden mdUp>
        <div className="nav">
          <div onClick={() => toggleDrawer(true)} className="drawer-toggle-div">
            <FontAwesomeIcon icon={faBars} className="drawer-toggle-icon" />
          </div>
          <Drawer
            anchor="left"
            open={showDrawer}
            onClick={() => setShowDrawer(false)}
            PaperProps={{
              sx: {
                minWidth: '300px',
              },
            }}
          >
            <div onClick={() => toggleDrawer(false)} className="drawer-menu-close-cross">
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="nav-logo-container ">
              <NavLink to="/" exact id="nav-logo">
                <GetImageLogo />
              </NavLink>
            </div>

            <div className="drawer-menu">
              {mainListItems()}
              <div className="drawer-menu-header">
                <div className="drawer-menu-header-title">{props.getLangString('menu.title')}</div>
              </div>
              {subMainListItems()}
            </div>
          </Drawer>
        </div>
        {user &&
          <div className="logout-button-container">
            <Button className="logout-button" onClick={() => logOut()}>{props.getLangString('admin.logOut')}</Button>
          </div>
        }
        {isHomePage && (
          <div className="logo-container">
            <GetImageLogo />
          </div>
        )}
      </Hidden>

      <Hidden mdDown>
        <div className="nav">
          <div className="nav-item">
            <NavLink to="/" exact id="nav-logo">
              <GetImageLogo />
            </NavLink>
          </div>

          <div className="nav-item">
            {user &&
              <div className="logout-button-container">
                <Button className="logout-button large" onClick={() => logOut()}>{props.getLangString('admin.logOut')}</Button>
              </div>
            }
            <div onClick={() => toggleDrawer(!showDrawer)} className="drawer-toggle-div">
              <FontAwesomeIcon icon={faBars} className="drawer-toggle-icon " />
            </div>
          </div>
          <Drawer
            className="rightdrawer"
            anchor="right"
            open={showDrawer}
            onClick={() => setShowDrawer(!showDrawer)}
            PaperProps={{
              sx: {
                height: '300px',
                minWidth: '350px',
                margin: '100px 0px 0px 0px',
                borderRadius: '0px 0px 0px 12px',
                overflow: 'hidden',
              },
            }}
          >
            <div className="drawer-menu">
              {mainListItems()}
              <div className="drawer-menu-header">
                <div className="drawer-menu-header-title">{props.getLangString('menu.title')}</div>
              </div>
              {subMainListItems()}
            </div>
          </Drawer>
        </div>
      </Hidden>
    </React.Fragment>
  );
};
