import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { AUTH_AUTHORITY, AUTH_CLIENT_ID, AUTH_SCOPE } from "./Constants";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: AUTH_CLIENT_ID,
        authority: AUTH_AUTHORITY,
        redirectUri: "/admin",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

//Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [AUTH_SCOPE]
};