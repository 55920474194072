import axios from 'axios';
import { API_ROOT, FUNCTIONS_ROOT } from '../../Constants';
import * as MarkerActions from './ActionTypes';
import * as AppStateActions from '../AppState/ActionTypes';
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux';
import { RootState } from '../RootReducer';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { IPolyline } from './Marker';
import { isNullEmptyOrUndefined } from '../../utils/helpers/ObjectHelper';

export const getMarkers = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ type: AppStateActions.CLEAR_ERROR });
        dispatch({ type: MarkerActions.GET_MARKERS_BEGIN });

        const result = await axios.get(`${API_ROOT}/marker`);
        dispatch({
            type: MarkerActions.GET_MARKERS_SUCCESS,
            payload: result.data
        });
    } catch (error: any) {
        console.error(error);
        dispatch({ type: MarkerActions.GET_MARKERS_FAILURE, payload: error });
        if (error.message === "Network Error") {
            dispatch({ type: AppStateActions.SET_ERROR, payload: { errorMessage: 'Network Error', error: error } });
        } else {
            dispatch({ type: AppStateActions.SET_ERROR, payload: { errorMessage: 'Get markers failure', error: error } });
        }
    }
}

export const getUpdatesForMarkers = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const connection = new HubConnectionBuilder()
        .withUrl(FUNCTIONS_ROOT)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    const newMessage = (message: string) => {
        const markers = JSON.parse(message);
        console.log('GOT NEW MESSASGE!!!', markers);

        dispatch({ type: MarkerActions.SIGNALR_MARKER_UPDATED, payload: markers });
    }

    // Events for the SignalR Connection
    connection.on('updatedMarker', newMessage);
    connection.onclose(() => console.log('disconnected'));
    connection.onreconnecting(() => {
        console.log('Reconnected to the hub, need to fetch all the markers from server to get changes from the unconnected period')
        dispatch(getMarkers());
        dispatch({ type: MarkerActions.SIGNALR_CONNECTING });
    })
    connection.onreconnected(() => {
        dispatch({ type: MarkerActions.SIGNALR_CONNECTED });
    });

    console.log('connecting...');
    dispatch({ type: MarkerActions.SIGNALR_CONNECTING });

    connection.start()
        .then(() => {
            console.log("connected..");
            dispatch({ type: MarkerActions.SIGNALR_CONNECTED });
        })
        .catch((error) => {
            console.error(error);
            dispatch({ type: MarkerActions.SIGNALR_FAILURE, payload: error });
        });
}

export const setMarkerDialogAsOpen = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    dispatch({ type: MarkerActions.SET_MARKER_DIALOG_OPEN, payload: id });
}

export const setMarkerIndexDialogAsOpen = (index: number): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    dispatch({ type: MarkerActions.SET_MARKER_INDEX_DIALOG_OPEN, payload: index });
}

export const setShowAllLiveMarkers = (showAllLiveMarkers: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    dispatch({ type: MarkerActions.SET_SHOW_ALL_LIVE_MARKERS, payload: showAllLiveMarkers });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePolylines= (polyline: IPolyline[]): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: MarkerActions.UPDATE_POLYLINES,
            payload: polyline
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: MarkerActions.UPDATE_POLYLINES_FAILURE, payload: error })
    }
}

export const cleanPolylines = (polylines: IPolyline[]): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        polylines.forEach((polyline: IPolyline) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            polyline.polylines.forEach((poly: any) => {
                if (!isNullEmptyOrUndefined(poly)) {
                    poly.setMap(null);
                }
            })
        })
       
        dispatch({
            type: MarkerActions.CLEAN_POLYLINES,
        });

    } catch (error) {
        console.error(error);
    }
}

export const setClickedOnPolyline = (clickedOnPolyline: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    dispatch({ type: MarkerActions.CLICKED_ON_POLYLINE, payload: clickedOnPolyline });
}



