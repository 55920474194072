import React, { useEffect, useState, MouseEvent, KeyboardEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { IInfoBox, IMarker, IProperty, MarkerSource } from '../../../store/Markers/Marker';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faExternalLinkAlt,
  fas,
  IconName,
} from '@fortawesome/free-solid-svg-icons';
import useDeviceDetect from '../../../utils/hooks/DeviceDetect';
import { Live } from '../../common/live/Live';
import { Button, Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useDispatch, useSelector } from 'react-redux';
import { MarkerIsLiveHours } from '../../../store/Settings/Selector';
import { MarkerIsLive } from '../../../utils/helpers/MarkerHelper';
import { Share } from '../../common/share/Share';
import { setMarkerIndexDialogAsOpen } from '../../../store/Markers/Actions';
import { MarkerIndexDialogOpen } from '../../../store/Markers/Selector';
import { SEO } from '../../common/seo/SEO';
import { THEME_KEY } from '../../../Constants';

// Add all icons to the library so you can use it in your page

export interface IInfoBoxProps {
  getLangString: (value: string) => string;
  marker: IMarker;
  setShowInfoBox: (value: boolean) => void;
  markerType?: string;
}

export const InfoBox = (props: IInfoBoxProps): JSX.Element => {
  const indexOpen = useSelector(MarkerIndexDialogOpen);
  const markerIsLiveHours = useSelector(MarkerIsLiveHours);
  const { isMobile } = useDeviceDetect();
  const [index, setIndex] = useState(indexOpen ?? 0);
  const [infoBoxInfo, setInfoBoxInfo] = useState<IInfoBox>();
  const [markerIsLive, setMarkerIsLive] = useState(false);
  const infoBoxRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  // Need to add all the icons to be able to load them dynamicly below.
  library.add(fas);

  const handleClick = (event: MouseEvent | KeyboardEvent | undefined) => {
    event?.stopPropagation();
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      event.stopPropagation();
    };

    const infoBox = infoBoxRef.current;
    if (infoBox) {
      infoBox?.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (infoBox) {
        infoBox?.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (indexOpen && indexOpen > 0 && props.marker.siblings && props.marker.siblings?.length >= indexOpen) {
      setSiblingAsInfoBox(indexOpen);
    } else {
      handleInfoBox();
    }
    setMarkerIsLive(MarkerIsLive(props.marker, markerIsLiveHours));
  }, [props.marker]);

  useEffect(() => {
    if (
      indexOpen &&
      indexOpen > 0 &&
      props.marker.siblings &&
      props.marker.siblings?.length >= indexOpen &&
      props.marker.siblings[indexOpen - 1].id !== infoBoxInfo?.id
    ) {
      setSiblingAsInfoBox(indexOpen);
    }
  }, [indexOpen]);

  const handleInfoBox = () => {
    setInfoBoxInfo({
      title: props.marker.title,
      id: props.marker.id,
      description: props.marker.description,
      address: props.marker.address,
      imageUrl: props.marker.imageUrl,
      link: props.marker.link,
      detailsUrl: props.marker.detailsUrl,
      bookingLink: props.marker.bookingLink,
      dynamicContent: props.marker.dynamicContent,
      subTypes: props.marker.subTypes,
      source: props.marker.source,
    });
  };

  const setSiblingAsInfoBox = (nextIndex: number) => {
    const siblingIndex = nextIndex - 1;
    if (props.marker.siblings && props.marker.siblings?.length >= siblingIndex) {
      setInfoBoxInfo({
        title: props.marker.siblings[siblingIndex].title,
        id: props.marker.siblings[siblingIndex].id,
        description: props.marker.siblings[siblingIndex].description,
        imageUrl: props.marker.siblings[siblingIndex].imageUrl,
        link: props.marker.siblings[siblingIndex].link,
        detailsUrl: props.marker.siblings[siblingIndex].detailsUrl,
        address: props.marker.siblings[siblingIndex].address,
        dynamicContent: props.marker.siblings[siblingIndex].dynamicContent,
        bookingLink: props.marker.siblings[siblingIndex].bookingLink,
        source: props.marker.siblings[siblingIndex].source,
        subTypes: props.marker.siblings[siblingIndex].subTypes
      });
    }
  };

  const handleChangeInfoBoxInformation = (isNext: boolean) => {
    if (props.marker.siblings && props.marker.siblings.length > 0) {
      const nextIndex = isNext ? index + 1 : index - 1;
      if (nextIndex <= props.marker.siblings.length && nextIndex >= 0) {
        if (nextIndex === 0) {
          handleInfoBox();
        } else {
          setSiblingAsInfoBox(nextIndex);
        }
        props.setShowInfoBox(true);
        setIndex(nextIndex);

        const params = new URLSearchParams(history.location.search);
        params.set('index', nextIndex.toString());
        history.push({ search: params.toString() });
        dispatch(setMarkerIndexDialogAsOpen(nextIndex));
      }
    }
  };

  const getMarkerSourceLabel = (source: MarkerSource): string => {
    switch (source) {
      case MarkerSource.Naturkartan:
        return 'Naturkartan';
      case MarkerSource.Iot:      
      case MarkerSource.GIS:
        return 'Ronneby Kommun';
      case MarkerSource.Event:
      case MarkerSource.CityBreak:
        if (THEME_KEY === 'olofstrom') {
          return 'Visit Olofström';
        } else {
          return 'Visit Ronneby';
        }
      case MarkerSource.Trafikverket:
        return 'Trafikverket';
      case MarkerSource.DataScraping:
        return 'Centrumbiografen';
      case MarkerSource.SvenskaLag:
        return 'Svenska Lag';
      case MarkerSource.Hjartstartarregistret:
        return 'Hjärtstartarregistret';
      default:
        return '';
    }
  };

  return (
    <div
      tabIndex={0}
      className={`info-box-container ${isMobile ? 'bottom' : 'right'} ${props.markerType ? props.markerType : ''} `}
      onClick={event => handleClick(event)}
    >
      <div className='info-box'> 
        {infoBoxInfo && (
          <SEO
          title={`${infoBoxInfo?.title} | ${props.getLangString('global.pageTitle')}`}
          description={infoBoxInfo?.description}
          imageUrl={infoBoxInfo?.imageUrl}
          />
        )}

        {markerIsLive && <Live getLangString={props.getLangString} />}

        <div id="scroll-container" ref={infoBoxRef}>
          <div className="scroll-box">
            {infoBoxInfo && infoBoxInfo.imageUrl != null && (
              <div className="image-container">
                <img src={infoBoxInfo.imageUrl} alt={infoBoxInfo.title} className="image" />
              </div>
            )}

            {infoBoxInfo && infoBoxInfo.title && <div className="title" lang="sv">{infoBoxInfo.title}</div>}
            {infoBoxInfo && infoBoxInfo.subTypes && infoBoxInfo.subTypes.length > 0 && (
              <React.Fragment>
                {infoBoxInfo.subTypes.map((subtype, index) => (
                  <React.Fragment key={index}>
                    <div className="subTypes">{subtype.title}</div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
            {infoBoxInfo && infoBoxInfo.address && (
              <div className="address">
                {infoBoxInfo.address.street}
                {infoBoxInfo.address.street && (infoBoxInfo.address.zip || infoBoxInfo.address.city) ? (
                  <span>,</span>
                ) : (
                  ''
                )}{' '}
                {infoBoxInfo.address.zip} {infoBoxInfo.address.city}
              </div>
            )}
            {infoBoxInfo && infoBoxInfo.description && (
              <div className={`description`}>
                {props.marker.source === MarkerSource.Naturkartan ||
                props.marker.source === MarkerSource.Hjartstartarregistret ||
                props.marker.source === MarkerSource.Iot ||
                props.marker.source === MarkerSource.CityBreak ||
                props.marker.source === MarkerSource.Event ? (
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{infoBoxInfo.description}</ReactMarkdown>
                ) : (
                  <React.Fragment>{infoBoxInfo.description}</React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        {infoBoxInfo && infoBoxInfo.source in MarkerSource && (
          <Grid container>
            <Grid item xs={12}>
              <div className="source">
                <span>{props.getLangString('map.infoBox.source') + getMarkerSourceLabel(infoBoxInfo.source)}</span>
              </div>
            </Grid>
          </Grid>
        )}
        {infoBoxInfo &&
          infoBoxInfo.dynamicContent &&
          ((markerIsLive && props.marker.source === MarkerSource.Iot) || props.marker.source !== MarkerSource.Iot) &&
          infoBoxInfo.dynamicContent.properties.length > 0 && (
            <Grid container className="icon-container">
              {infoBoxInfo.dynamicContent.properties.map((prop: IProperty, index: number) => (
                <React.Fragment key={index}>
                  <Grid item xs={1} className="icon-col">
                    <FontAwesomeIcon
                      icon={['fas', prop.icon as IconName]}
                      className={`icon ${prop.class}`}
                      style={{ transform: `rotate(${prop.value}deg)` }}
                    />
                  </Grid>
                  <Grid item xs={11} className="">
                    {prop.link != null ? (
                      <Link className={`icon-label`} to={{ pathname: prop.link }} target="_blank">
                        {' '}
                        {prop.label}{' '}
                      </Link>
                    ) : (
                      <div className="icon-label">{prop.label}</div>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          )}
        {infoBoxInfo && infoBoxInfo.bookingLink && (
          <Grid container>
            <Grid item xs={12}>
              <Button href={infoBoxInfo.bookingLink} target="_blank" className="booking-link">
                <span>{props.getLangString('map.bookTicket')}</span>
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={6}>
            {infoBoxInfo && infoBoxInfo.detailsUrl && (
              <div className="details-url">
                <Link to={{ pathname: infoBoxInfo.detailsUrl }} target="_blank">
                  {props.getLangString('map.readMore')}
                </Link>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" />
              </div>
            )}
            {infoBoxInfo && (!infoBoxInfo.detailsUrl || infoBoxInfo.detailsUrl.length === 0) && infoBoxInfo.link && (
              <div className="details-url">
                <Link to={{ pathname: infoBoxInfo.link }} target="_blank">
                  {props.getLangString('map.link')}
                </Link>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" />
              </div>
            )}
          </Grid>
          <Grid item xs={6} className="share-url">
            {infoBoxInfo && (
              <Share title={infoBoxInfo.title} getLangString={props.getLangString} onInfoBox={true} showIcon={true} />
            )}
          </Grid>
        </Grid>

        {props.marker.siblings && props.marker.siblings.length > 0 && (
          <Grid container className="multiple-events">
            <Grid item xs={12} className="hr-grid-item">
              <div className="horizontalRow">
                <hr></hr>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="button left"
                disabled={index <= 0 ? true : false}
                onClick={() => handleChangeInfoBoxInformation(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="icon" size="lg" />
              </Button>
            </Grid>
            <Grid item xs={6} className="info-text">
              <span>{`${props.getLangString('map.show')} ${index + 1} ${props.getLangString('map.of')} ${
                props.marker.siblings.length + 1
              } ${props.getLangString('filter.pins')}`}</span>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="button right"
                disabled={index >= props.marker.siblings.length ? true : false}
                onClick={() => handleChangeInfoBoxInformation(true)}
              >
                <FontAwesomeIcon icon={faArrowRight} className="icon" size="lg" />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
