import axios from 'axios';
import { API_ROOT } from '../../Constants';
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../RootReducer';
import { Action } from 'redux';
import * as SettingsActions from './ActionTypes'
import { ISettings } from './Settings';

export const getSettings = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ type: SettingsActions.GET_SETTINGS_BEGIN });

        const result = await axios.get(`${API_ROOT}/Setting`);
        dispatch({
            type: SettingsActions.GET_SETTINGS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: SettingsActions.GET_SETTINGS_FAILURE, payload: error })
    }
}

export const updateSettings = (updatedSettings: ISettings, accessToken: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        
        dispatch({ type: SettingsActions.SET_SETTINGS_BEGIN });

        if (accessToken === "" || accessToken === undefined) {
            dispatch({ type: SettingsActions.SET_SETTINGS_FAILURE, payload: "You do not have access to perform this action" })
        } else {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }
            const queryString: string = JSON.stringify(updatedSettings);

            const result = await axios.post(`${API_ROOT}/Setting`, queryString, { headers: headers });

            dispatch({ type: SettingsActions.SET_SETTINGS_SUCCESS, payload: result.data });
        }

    } catch (error) {
        console.error(error);
        dispatch({ type: SettingsActions.SET_SETTINGS_FAILURE, payload: "Failed to update default filters, please contact administrator" })
    }
} 