import { ISettings } from "./Settings";

export const GET_SETTINGS_BEGIN = 'GET_SETTINGS_BEGIN';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const SET_SETTINGS_BEGIN = 'SET_SETTINGS_BEGIN';
export const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS';
export const SET_SETTINGS_FAILURE = 'SET_SETTINGS_FAILURE';

export interface ISettingsState {
    defaultFilters: string[];
    defaultEventCategories: string[];
    markerIsLiveHours: number;
    loading: boolean,
    error?: string
}

interface GetSettingsBeginAction {
    type: typeof GET_SETTINGS_BEGIN
}

interface GetSettingsSuccessAction {
    type: typeof GET_SETTINGS_SUCCESS,
    payload: ISettings
}

interface GetSettingsFailure {
    type: typeof GET_SETTINGS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface SetSettingsBeginAction {
    type: typeof SET_SETTINGS_BEGIN
}

interface SetSettingsSuccessAction {
    type: typeof SET_SETTINGS_SUCCESS,
    payload: ISettings
}

interface SetSettingsFailure {
    type: typeof SET_SETTINGS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

export type SettingsActionTypes = GetSettingsBeginAction | GetSettingsSuccessAction | GetSettingsFailure | SetSettingsBeginAction | SetSettingsSuccessAction | SetSettingsFailure;
