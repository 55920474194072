import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { AdminToolbox } from '../components/admin/AdminToolbox';
import './ThemeAdmin.scss';
import { useAuth } from '../services/auth-context';
import { Loader } from '../components/common/loader/Loader';

export interface IAdminProps {
    getLangString: (value: string) => string;
}

export const Admin = (props: IAdminProps): JSX.Element => {
    const { user, refreshTokensAndUserInfo } = useAuth();

    useEffect(() => {
        refreshTokensAndUserInfo();
    }, []);

    return (
        user == null ?
            <Loader getLangString={props.getLangString} />
            :
            user?.aadAccess ?
                <Container role="main">
                    <AdminToolbox getLangString={props.getLangString} />
                </Container>
                :
                <div className="no-access-wrapper">
                    <span className="no-access-title">{props.getLangString('admin.noAccessTitle')}</span>
                    <span className="no-access-description">{props.getLangString('admin.noAccessDescription')}</span>
                </div>
    );
};