import { Home } from '../../pages/Home';
import { Events } from '../../pages/Events';
import { Event } from '../../pages/Event';
import { ViewReport } from '../../pages/ViewReport';
import { About } from '../../pages/About';
import { Admin } from '../../pages/Admin';


const paths =  [
    { path: "/", name: "topNavigation.liveMap", Component: Home },
    { path: "/events", name: "topNavigation.whatHappensInRonneby", Component: Events },
    { path: "/events/:id/:start", name: "", Component: Event },
    { path: "/live/report/:id", name: "", Component: ViewReport },
    { path: "/about", name: "topNavigation.aboutRonneby", Component: About },
    { path: "/admin", name: "Admin", Component: Admin },
];

export default paths;