export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const FUNCTIONS_ROOT: string = process.env.REACT_APP_FUNTIONS_ROOT == null ? '' : process.env.REACT_APP_FUNTIONS_ROOT;
export const LOCAL_DB_ROOT = process.env.REACT_APP_LOCAL_DB_ROOT;
export const GOOGLE_MAP_KEY: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY == null ? '' : process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_ANALYTICS_KEY: string = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY == null ? '' : process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
export const PRISMIC_URL: string = process.env.REACT_APP_PRISMIC_URL == null ? '' : process.env.REACT_APP_PRISMIC_URL;
export const PRISMIC_TOKEN: string = process.env.REACT_APP_PRISMIC_TOKEN == null ? '' : process.env.REACT_APP_PRISMIC_TOKEN;
export const THEME_KEY : string =  process.env.REACT_APP_THEME_KEY == null ? 'ronneby': process.env.REACT_APP_THEME_KEY;
export const GOOGLE_MAP_Position = process.env.REACT_APP_MapPosition ?? '';
export const CACHE_NAME: string = process.env.REACT_APP_CACHE_NAME ?? '';
export const COOKIE_URL: string = process.env.REACT_APP_COOKIE_URL ?? '' ;
export const AUTH_CLIENT_ID: string = process.env.REACT_APP_AUTH_CLIENT_ID ?? '';
export const AUTH_AUTHORITY: string = process.env.REACT_APP_AUTH_AUTHORITY ?? '';
export const AUTH_SCOPE: string = process.env.REACT_APP_AUTH_SCOPE ?? '';
export const ADMIN_GROUP_ID: string = process.env.REACT_APP_ADMIN_GROUP_ID ?? '';
export const AUTH_REDIRECT_URI: string = process.env.REACT_APP_AUTH_REDIRECT_URI ?? '';