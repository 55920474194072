import React from 'react'
import './ThemeAdminTool.scss';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IAdminToolProps {
    getLangString: (value: string) => string;
}

export const AdminTool = (props: IAdminToolProps): JSX.Element => {
    return (
        <div className="admin-tool-container">
            <span className="admin-title">{props.getLangString('admin.adminTitle')}</span>
            <span className="admin-text">{props.getLangString('admin.adminSubTitle')}</span>
            <span className="admin-text">{props.getLangString('admin.adminDescription')}</span>
            <FontAwesomeIcon icon={faTools} className="admin-tool-icon" />
        </div>
    );
}