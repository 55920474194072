import {
    IMarkerState,
    MarkerActionTypes,
    GET_MARKERS_BEGIN,
    GET_MARKERS_FAILURE,
    GET_MARKERS_SUCCESS,
    SIGNALR_CONNECTING,
    SIGNALR_CONNECTED,
    SIGNALR_DISCONNECTED,
    SIGNALR_FAILURE,
    SIGNALR_MARKER_UPDATED,
    SET_MARKER_DIALOG_OPEN,
    SET_MARKER_INDEX_DIALOG_OPEN,
    UPDATE_POLYLINES,
    UPDATE_POLYLINES_FAILURE,
    CLEAN_POLYLINES,
    CLICKED_ON_POLYLINE,
    SET_SHOW_ALL_LIVE_MARKERS
} from './ActionTypes';
import { IMarker } from './Marker';



const initialState: IMarkerState = {
    markersList: [],
    polylines: [],
    loading: false,
    connected: false,
    clickedOnPolyline: false,
    showAllLiveMarkers: false,
}

const markerReducer = (state = initialState, action: MarkerActionTypes): IMarkerState => {
    switch (action.type) {
        case GET_MARKERS_BEGIN:
            return {
                ...state,
                loading: true
            };
        case GET_MARKERS_SUCCESS:
            return {
                ...state,
                markersList: action.payload,
                loading: false
            }
        case GET_MARKERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SIGNALR_CONNECTING:
            return {
                ...state,
                connected: false
            }
        case SIGNALR_CONNECTED:
            return {
                ...state,
                connected: true
            }
        case SIGNALR_DISCONNECTED:
            return {
                ...state,
                connected: false
            }
        case SIGNALR_FAILURE:
            return {
                ...state,
                connected: false,
                error: action.payload
            }
        case SIGNALR_MARKER_UPDATED:
            // Check if the marker exists in the array or not
            console.log('MARKERS UPDATED', action.payload);

            if (state.markersList === null) {
                return {
                    ...state,
                    markersList: action.payload
                }
            } else {
                let newMarkers = [ ...state.markersList ];
        
                action.payload?.forEach((marker: IMarker) => {
                    if (state.markersList?.some(e => e.id === marker.id)) {
                        console.log('Found the maker in the array..');
        
                        // The item is in the array, check if this is a delete, we are using 
                        // soft deleted in the backend, but we remove the item in the store
                        if (marker.deleted) {
                            console.log('The marker from back-end was deleted, removing marker');
                            newMarkers = newMarkers?.filter(m => m.id !== marker.id)
                        } else {
                            newMarkers = newMarkers?.map((m) => {
                                if (marker.id !== m.id) {
                                    return m;
                                }
                                return {
                                    ...m,
                                    ...marker
                                }
                            })
                        }
                    } else {
                        console.log('Marker not found in the array, adding .. ');
                        newMarkers =  [...newMarkers, marker]
                    }
                });

                return {
                    ...state,
                    markersList: newMarkers
                }
            }
        case UPDATE_POLYLINES: 
            return {
                ...state,
                polylines: action.payload
            }
        case UPDATE_POLYLINES_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case CLEAN_POLYLINES:
            return {
                ...state,
                polylines: []
            }
        case CLICKED_ON_POLYLINE:
            return {
                ...state,
                clickedOnPolyline: action.payload
            }
        case SET_MARKER_DIALOG_OPEN:
            return {
                ...state,
                markerWithDialogOpen: action.payload
            }
        case SET_MARKER_INDEX_DIALOG_OPEN:
            return {
                ...state,
                markerIndexWithDialogOpen: action.payload
            }
        case SET_SHOW_ALL_LIVE_MARKERS:
            return {
                ...state,
                showAllLiveMarkers: action.payload
            }
        default:
            return state;
    }
}

export default markerReducer;
