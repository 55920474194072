import React from 'react'
import './ThemeAdminToolbox.scss';
import { Grid } from '@material-ui/core';
import { AdminTools } from '../../store/Settings/Settings';
import { AdminTool } from './tools/admin/AdminTool';
import { FilterTool } from './tools/filter/FilterTool';

export interface IAdminToolboxProps {
    getLangString: (value: string) => string;
}

export const AdminToolbox = (props: IAdminToolboxProps): JSX.Element => {
    const [currentTool, setCurrentTool] = React.useState<AdminTools>(AdminTools.Admin);

    const handleToolChange = (tool: AdminTools) => {
        setCurrentTool(tool);
    };

    return (
        <Grid container className="admin-container">
            <Grid item xs={12} md={2}>
                <div className="admin-options-container">
                    <div className={`tool-option ${currentTool === AdminTools.Admin && "selected"}`} onClick={() => handleToolChange(AdminTools.Admin)}>
                        {props.getLangString('admin.admin')}
                    </div>
                    <div className={`tool-option ${currentTool === AdminTools.Filter && "selected"}`} onClick={() => handleToolChange(AdminTools.Filter)}>
                    {props.getLangString('admin.filter')}
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={10}>
                <div className="admin-view-container">
                    {currentTool === AdminTools.Admin &&
                        <AdminTool getLangString={props.getLangString} />
                    }
                    {currentTool === AdminTools.Filter &&
                        <FilterTool getLangString={props.getLangString} />
                    }
                </div>
            </Grid>
        </Grid>
    )
}
