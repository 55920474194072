import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ThemeIndex.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Store from './store/Store';
import { Provider } from 'react-redux';
import './i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './services/auth-context';

ReactDOM.render(
  <AuthProvider>
  <Provider store={Store} >
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}